<template>
  <div class="content">
    <PageHeader :title="$tc('generic-str.plan', 2)">
      <template v-slot:actions>
        <li>
          <button
            class="btn btn-success btn-floating"
            @click="$refs['plan-modal'].toggle()"
          >
            <i
              class="
                la la-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </button>
        </li>
      </template>
    </PageHeader>
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <card
            :title="$tc('generic-str.plan', 2)"
            fetchable
            :fetched="fetched"
          >
            <div class="row">
              <div class="col-12">
                <data-table
                  :columns="[
                    {
                      key: 'service',
                      class: 'badge badge-primary',
                      label: 'type',
                    },
                    'name',
                    {
                      key: 'pivot.pricing.fee',
                      label: 'estimate_price',
                      filters: ['currency'],
                    },
                  ]"
                  :items="plans"
                >
                  <template #actions="item">
                    <router-link
                      :to="{ name: '', params: { planId: item.id } }"
                    >
                      <i class="la la-eye"></i>
                    </router-link>
                  </template>
                </data-table>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <pagination :lastPage="lastPage" />
              </div>
            </div>
          </card>
        </div>
      </div>
    </section>
    <modal ref="plan-modal" :title="$t('generic-str.new-plan')" @submit="store">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('name') }}
        </label>
        <div class="col-sm-10">
          <input type="text" class="form-control" v-model="form.name" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">
          {{ $t('type') }}
        </label>
        <div class="col-sm-10">
          <select class="form-control" v-model="form.service" required>
            <option value="sms" selected>SMS</option>
            <!-- <option value="omni">Omni Business</option> -->
            <option value="omni">Chat</option>
            <option value="whatsapp">Whatsapp API</option>
            <option value="rcs">Google RCS</option>
            <option value="email">E-Mail</option>
            <option value="lookup">Lookup</option>
          </select>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';
import Pagination from '@/components/Pagination.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'PlansIndex',
  components: {
    Card,
    PageHeader,
    DataTable,
    Pagination,
    Modal,
  },
  data() {
    return {
      fetched: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      plans: null,
      form: {},
      lastPage: 1,
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch('fetchPlans').then((response) => {
        this.fetched = true;
        this.plans = response.data.data;
      });
    },
    store() {
      this.fetched = false;
      this.$store.dispatch('storePlans', this.form).then((response) => {
        this.fetch();
      });
    },
    update(planId) {
      this.fetched = false;
      this.$store
        .dispatch('updatePlans', {
          planId,
          input: this.form,
        })
        .then((response) => {
          this.fetch();
        });
    },
    destroy(planId) {
      this.fetched = false;
      this.$store.dispatch('destroyPlan', planId).then((response) => {
        this.fetch();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.badge {
  text-transform: uppercase;
}
table {
  thead {
    text-transform: uppercase;
  }
  tbody {
    tr:hover {
      background-color: #f0f6ff;
    }
  }
}
</style>
